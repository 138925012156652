@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Fira Code';
  background-color: white;

  background-image: radial-gradient(circle at center, #172554, #1e3a8a);
  background-size: 150% 150%;
  background-repeat: no-repeat;
  background-position: center;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
