.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;

  @apply bg-blue-300 h-40 m-3 p-5 rounded-3xl shadow-lg transition duration-500 ease-in-out
}

.footer:hover {
  transform: scale(1.02);
}

.footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @apply font-bold hover:scale-110 transition duration-500 ease-in-out
}

.footer-item img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  @apply m-2
}

.footer-item p {
  @apply m-2
}
