.vertical-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  min-height: 480px;

  @apply from-blue-950 to-blue-900 m-3 p-5 rounded-3xl shadow-lg border-blue-700 border-solid border-2 transition duration-500 ease-in-out
}

.vertical-block:hover {
  transform: scale(1.02);
}

.text-vertical-block {
  @apply w-3/4 text-white
}

.text-vertical-block h2 {
  text-align: left;
  @apply w-1/4 text-cyan-300 text-6xl font-extrabold m-4 p-2 
}

.text-vertical-block p {
  text-align: left;
  @apply w-3/4 m-2 p-2 font-semibold text-base
}

.img-vertical-block {
  @apply w-1/4;
}

.img-vertical-block img {
  width: 256px;
  height: 256px;
  display: block;
  margin: center;
  @apply p-2
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  50% { border-color: transparent; }
}
